<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    <feather-icon size="16" icon="FilterIcon"/>
                    فیلتر
                </b-button>
                <!--            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info">-->
                <!--                <feather-icon size="17" icon="FilePlusIcon"/>-->
                <!--                افزودن سفارش-->
                <!--            </b-button>-->
            </div>

            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1,perPage)"
                    @reset="getData(1,perPage)"
            />

            <history-details v-if="false" :is-active.sync="isDetails" :order="items[1] || []"/>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >
                    <template #cell(email)="data">
                        <span>
                            {{ data.item.user.email }}
                        </span>
                    </template>

                    <template #cell(type)="data">
                        <b-badge
                                pill
                                :variant="'light-'+typeVariant(data.item.type)"
                                class="text-capitalize"
                        >
                            {{ typeLabel(data.item.type) }}
                        </b-badge>
                    </template>

                    <template #cell(market)="data">
                        <b-avatar
                                size="22"
                                class="my-50"
                                :src="data.item.market.coin.logo"
                                v-tooltip="data.item.market.coin.label"
                                :variant="`light-info`"
                        />
                        /
                        <b-avatar
                                size="22"
                                class="my-50"
                                :src="data.item.market.quote.logo"
                                v-tooltip="data.item.market.quote.label"
                                :variant="`light-info`"
                        />
                    </template>

                    <template #cell(amount)="data">
                        <span dir="ltr">
                            {{
                                $toLocal(data.item.amount)
                            }}
                            <span style="font-size: 0.75em; vertical-align: bottom" class="text-primary">
                            {{ data.item.market.coin.code }}
                            </span>
                        </span>
                    </template>

                    <template #cell(price)="data">
                        <span dir="rtl">
                            {{ $toLocal(data.item.price) }} <span
                                class="text-primary"
                                style="font-size: 0.75em; vertical-align: bottom"
                        >{{
                                data.item.market.quote.code
                            }}</span>
                        </span>
                    </template>

                    <template #cell(total)="data">
                        <span dir="rtl">
                            {{ $toLocal(data.item.total) }}
                        </span>
                    </template>

                    <template #cell(created_at)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.created_at) }}
                        </span>
                    </template>

                    <template #cell(status)="data">
                        <b-badge
                                pill
                                :variant="'light-'+orderStatusVariant(data.item.status)"
                                class="text-capitalize"
                        >
                            {{ orderStatusLabel(data.item.status) }}
                        </b-badge>
                    </template>

                    <template #cell(action)="{item, index}">
                        <b-dropdown
                                id="dropdown-offset"
                                variant="link"
                                no-caret
                                offset="80px"
                                :right="true"
                                v-if="item.status==='PENDING' && $havePermission('DELETE_ORDERS')"
                        >
                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item v-if="item.status==='PENDING' && $havePermission('DELETE_ORDERS')"
                                             @click="acceptOrder(index)">
                                <feather-icon class="text-success" icon="CheckCircleIcon"/>
                                <span class="text-success align-middle ml-50">پذیرش سفارش</span>
                            </b-dropdown-item>

                            <b-dropdown-item v-if="item.status==='PENDING' && $havePermission('DELETE_ORDERS')"
                                             @click="rejectOrder(index)">
                                <feather-icon class="text-danger" icon="XCircleIcon"/>
                                <span class="text-danger align-middle ml-50">لغو سفارش</span>
                            </b-dropdown-item>

                            <b-dropdown-item v-if="false" @click="isDetails=true">
                                <feather-icon icon="FileTextIcon"/>
                                <span class="align-middle ml-50">جزییات سفارش</span>
                            </b-dropdown-item>
                        </b-dropdown>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(currentPage,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BPagination,
    BCard,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    BOverlay,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
import HistoryDetails from "@/views/Orders/historyDetails";
import NotAllowed from "@/layouts/components/NotAllowed";

export default {
    name: 'Orders',
    components: {
        NotAllowed,
        HistoryDetails,
        SearchAndFilter,
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        BAvatar,
        vSelect,
        BOverlay,
    },
    data: () => ({
        test: 0,
        pageLength: 3,
        dir: false,
        searchTerm: '',
        currentPage: 1,
        perPage: 10,
        rows: 20,
        sortBy: '',
        isSortDirDesc: '',
        userData: [],
        isActive: false,
        isDetails: false,
        perPageOptions: [5, 10, 20, 50, 100],
        items: [],
        columns: [
            {
                label: 'نام کاربری',
                key: 'email',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'نوع سفارش',
                key: 'type',
                sortable: true,
                searchType: 'select',
                selectOptions: [
                    {label: 'خرید', value: 'BUY'},
                    {label: 'فروش', value: 'SELL'},
                ]
            },
            {
                label: 'بازار',
                key: 'market',
                sortable: false,
                searchType: 'select',
                selectOptions: []
            },
            {
                label: 'مقدار',
                key: 'amount',
                sortable: false,
                searchType: 'number'
            },
            {
                label: 'قیمت واحد',
                key: 'price',
                sortable: true,
                searchType: 'number'
            },
            {
                label: 'ارزش کل',
                key: 'total',
                sortable: false,
                // searchType: 'number'
            },
            {
                label: 'وضعیت',
                key: 'status',
                sortable: false,
                searchType: 'select',
                selectOptions: [
                    {label: 'در حال انجام', value: 'PENDING'},
                    {label: 'تکمیل شده', value: 'ACCEPTED'},
                    {label: 'لغو شده', value: 'REJECTED'},
                    {label: 'لغو توسط کاربر', value: 'REJECTED_BY_USER'},
                    {label: 'منقضی شده', value: 'EXPIRED'}
                ]
            },
            {
                label: 'تاریخ ایجاد',
                key: 'created_at',
                sortable: false,
                searchType: 'date'
            },
            {
                label: 'عملیات',
                key: 'action'
            },
        ],
    }),
    computed: {
        orderStatusVariant() {
            const a = {
                PENDING: 'warning',
                ACCEPTED: 'success',
                REJECTED: 'danger',
                REJECTED_BY_USER: 'danger',
                EXPIRED: 'danger',

            }
            return e => a[e];
        },
        orderStatusLabel() {
            const a = {
                PENDING: 'در حال انجام',
                ACCEPTED: 'تکمیل شده',
                REJECTED: 'لغو شده',
                REJECTED_BY_USER: 'لغو توسط کاربر',
                EXPIRED: 'منقضی شده',
            }
            return e => a[e];
        },
        typeVariant() {
            const a = {
                BUY: 'success',
                SELL: 'danger',
            }
            return e => a[e];
        },
        typeLabel() {
            const a = {
                BUY: 'خرید',
                SELL: 'فروش',
            }
            return e => a[e];
        },
    },
    methods: {
        acceptOrder(e) {
            this.$swal({
                title: 'آیا از پذیرش سفارش کاربر مطمئن هستید؟',
                // text: "You won't be able to revert this!",
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'بله',
                cancelButtonText: 'خیر',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {

                    this.state.loading = true
                    const address = '/orders/' + this.items[e].id + '/accept'
                    await this.$axios.patch(address)

                    this.items[e].status = 'ACCEPTED'

                    this.$swal({
                        icon: 'success',
                        title: 'سفارش پذیرفته شد.',
                        confirmButtonText: 'تایید',
                        // text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })

                }
            })
        },
        rejectOrder(e) {
            this.$swal({
                title: 'آیا از لغو سفارش کاربر مطمئن هستید؟',
                // text: "You won't be able to revert this!",
                icon: 'error',
                showCancelButton: true,
                confirmButtonText: 'بله',
                cancelButtonText: 'خیر',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {

                    this.state.loading = true
                    const address = '/orders/' + this.items[e].id + '/reject'
                    await this.$axios.patch(address)

                    this.items[e].status = 'REJECTED'

                    this.$swal({
                        icon: 'success',
                        title: 'سفارش غیر فعال شد',
                        confirmButtonText: 'تایید',
                        // text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })

                }
            })
        },
        sort(e) {

            let sort = this.$toSnakeCase(e.sortBy)
            // let sort = e.sortBy
            let sorting = e.sortDesc ? 'DESC' : 'ASC'

            this.$router.push({
                query: {
                    ...this.$route.query,
                    orderBy: sort,
                    sorting: sorting
                }
            })

            this.getData(1, this.perPage)

        },
        async getData(page, perPage) {
            this.state.loading = true
            const queryParams = {
                size: perPage,
                page: page,
                ...this.$route.query
            }
            const res = await this.$axios('/orders', {params: queryParams})
            this.state.loading = false
            this.items = res.data.data
            this.currentPage = res.data.meta.current_page
            this.rows = res.data.meta.total
        },
    },
    created() {
        this.getData(1, this.perPage)
    }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
